import { ptComponents } from "@/utils/portableTextComp";
import * as styles from "./styles.css";
import { PortableText } from "@portabletext/react";
import { stegaClean } from "@sanity/client/stega";
import Badge from "../badge";

interface HeadingAndTextProps {
  headingText?: string;
  subHeadingText?: string;
  bodyText?: any[];
  textAlign?: 'center' | 'left' | 'right' | '';
  isFullWidth?: boolean;
  hasBottomPadding?: boolean;
  textAlignLeftMobile?: boolean;
  promDateRange?: string;
  badgePromo?: string;
  size?: 'default' | 'smaller';
}

export default function HeadingAndText(props: HeadingAndTextProps) {
  return (
    <div className={`${styles.container} ${props.textAlign ? stegaClean(props.textAlign) : 'center'}${props.isFullWidth ? ' is--full-width' : ''}${props.hasBottomPadding ? ' has--padding' : ''}${props.textAlignLeftMobile ? ' is--textAlignLeftMobile' : ''} content--narrow`}>
      <div className="hidden-anchor" id={stegaClean(props?.headingText)?.toLowerCase().replace(/[^A-Z0-9]/ig, "-")} />
      {props.headingText &&
        <h2 className={`${styles.heading}${props?.size === 'smaller' ? ' is--smaller' : undefined}`}>{props.headingText}</h2>
      }
      {props.subHeadingText && 
        <h3 className={styles.subheading}>{props.subHeadingText}</h3>
      }
      {(props.promDateRange || props.badgePromo) &&
        <div className={styles.dateContainer}>
          {props.promDateRange &&
            <Badge text={props.promDateRange} icon="calendar-days-filled" borderFull />
          }
          {props.badgePromo &&
            <Badge text={props.badgePromo} icon="exclamation-circle-filled" borderFull />
          }
        </div>
      }
      {props.bodyText && (
        <div className="portable-text">
          <PortableText value={props.bodyText} components={ptComponents} />
        </div>
      )}
    </div>
  );
}
