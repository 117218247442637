"use client";

import { useEffect, useRef } from "react";
import * as styles from "./styles.css";

interface ContentContainerProps {
  layout?:
    | "navigation"
    | "footer"
    | "footerSignup"
    | "subnav"
    | "hero"
    | "header-tier-2"
    | "header-tier-3"
    | "header-tier-4"
    | "header-policy"
    | "header-post"
    | "header-article-tier-3"
    | "social"
    | "cards"
    | "cardCarousel"
    | "cardBasicCarousel"
    | "breadcrumb"
    | "trading-hours"
    | "benefits-trading-hours"
    | "awards"
    | "awardsCard"
    | "instagramFeed"
    | "fullwidth"
    | "card-quick-links"
    | "medium-padding"
    | "cards-medium-padding"
    | "character"
    | "source"
    | "sourceNews"
    | "listing"
    | "promoCtaCard"
    | "header-promotion"
    | "article"
    | "search"
    | "header-contact"
    | "contact"
    | "divider"
    | undefined;
  children: React.ReactNode;
  alignment?: "left" | "center";
  className?: string;
}

export default function ContentContainer(props: ContentContainerProps) {
  const largeSectionPadding = ["cardCarousel", "cardBasicCarousel", "cards"];
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (container.current && ((props.layout && largeSectionPadding.includes(props.layout)) || !props.layout)) {
      if (container.current?.parentElement && container.current?.parentElement?.classList.contains('pagebuilder__container')) {
        container.current?.parentElement.classList.add('has--large-padding');
      } else if (container.current?.parentElement?.parentElement && container.current?.parentElement?.parentElement?.classList.contains('pagebuilder__container')) {
        container.current?.parentElement.parentElement.classList.add('has--large-padding');
      }
    }
  }, []);

  return (
    <div
      ref={container}
      className={`${styles.content({
        layout: props.layout,
        alignment: props.alignment,
      })} ${props.className ?? ""}${(props.layout && largeSectionPadding.includes(props.layout)) || !props.layout ? ' has--large-padding' : ''}`}
    >
      {props.children}
    </div>
  );
}
