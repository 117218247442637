import * as styles from "./styles.css";
import BiteMark from "../bite-mark";

export type BackgroundGraphicTypes = 
  | "snake" 
  | "tier2Curve" 
  | "tier3Curve"
  | "tail";

export interface PageSectionProps {
  layout?:
    | "hero"
    | "header-tier-1"
    | "header-tier-2"
    | "header-tier-3"
    | "header-tier-4"
    | "header-policy"
    | "header-contact"
    | "header-article-tier-3"
    | "navigation"
    | "page-break"
    | "header-promotion"
    | "promoCtaCardModule"
    | "showOverflow"
    | "social"
    | "contact"
    | "header-post";
  backgroundColor?:
    | "background-darken-3"
    | "background-lighten-5"
    | "status-success-darken-3"
    | "white"
    | "background-default"
    | "neutral-lighten-5"
    | "primary-darken-3"
    | "supporting-lighten-7";
  backgroundGraphic?: BackgroundGraphicTypes | undefined;
  biteMark?: boolean;
  children: React.ReactNode;
  className?: string;
}

export default function PageSection(props: PageSectionProps) {
  return (
    <div
      className={`${styles.container({
        layout: props.layout,
        background: props.backgroundColor,
        biteMark: props.biteMark,
        graphic: props.backgroundGraphic,
      })}${props.className ? ` ${props.className}` : ""} is--page-section`}
    >
      {props.biteMark && <BiteMark isPolicyHeader={props.layout === 'header-policy'} />}
      {props.children}
    </div>
  );
}
