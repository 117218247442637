import * as styles from "./styles.css";
import Claw1 from "./svg/claw1";
import Claw2 from "./svg/claw2";
import CrocR from "./svg/crocR";
import CrocL from "./svg/crocL";
import FernL from "./svg/fernL";
import FernR from "./svg/fernR";
import PawL from "./svg/pawL";
import PawR from "./svg/pawR";

interface GraphicProps {
  type?: "fern" | "claw1" | "claw2" | "croc" | "paw",
  layout?: "default" | "promoCtaCard"
  align?: "left" | "right" | "topLeft" | "topRight" | "centerTop" | "bottomLeft" | "bottomRight" | "centerBottom"
}

export default function Graphic(props: GraphicProps) {
  return (
    <div className={styles.container}>
      <div className={`graphic-content__container ${styles.content({
        graphic: props.type,
        alignment: props.align,
      })} is--${props.layout}`}>
        {props.type === "fern" && props.align?.toLowerCase().includes('left') && <FernL />}
        {props.type === "fern" && props.align?.toLowerCase().includes('right') && <FernR />}
        {props.type === "claw1" && <Claw1 />}
        {props.type === "claw2" && <Claw2 />}
        {props.type === "croc" && props.align?.toLowerCase().includes('left') && <CrocL />}
        {props.type === "croc" && props.align?.toLowerCase().includes('right') && <CrocR />}
        {props.type === "paw" && props.align?.toLowerCase().includes('left') && <PawL />}
        {props.type === "paw" && props.align?.toLowerCase().includes('right') && <PawR />}
      </div>
    </div>
  )
}