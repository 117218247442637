import * as styles from "./styles.css";
import Icon from "@/elements/icons";

interface BadgeProps {
  text: string;
  icon?: "exclamation-circle-filled" | "calendar-days-filled";
  borderFull?: boolean;
  className?: string;
  size?: "large" | 'base'
}

export default function Badge(props: BadgeProps) {
  return (
    <div className={`${props.className} ${styles.container({ type: props.borderFull ? 'full' : 'cropped', size: props?.size ?? 'base'})}`}>
      {props.icon && (
        <div className={styles.icon}>
          <Icon type={props.icon} />
        </div>
      )}
      {props.text}
    </div>
  );
}
