import { format } from 'date-fns';
import * as styles from "./syles.css";
import PageSection from "@/components/page-section";
import ContentContainer from "@/components/content-container";
import Image from "next/image";
import ButtonContainer from "@/components/button-container";
import Button from "@/components/button";
import { stegaClean } from "@sanity/client/stega";
import { TextAndImageModule } from "@/types/sanity.types";
import HeadingAndText from "@/components/heading-and-text";
import Video from "@/components/video";
import Graphic from '@/elements/graphic';

interface Props extends TextAndImageModule {
  _key: string;
  video?: any;
  videoPlaceholder?: any;
  image?: any;
  promoStartDate?: string;
  promoEndDate?: string;
  isArticle?: boolean;
}

export default function TextandImage(props: Props) {
  let promDateRange = '';
  
  if (props.promoStartDate || props.promoEndDate) {
    promDateRange = `
    ${props.promoStartDate ? format(new Date(props.promoStartDate), 'd MMMM') : ''}${props.promoStartDate ? ' - ' : ''}${props.promoEndDate ? format(new Date(props.promoEndDate), 'd MMMM') : ''}
    `;
  }

  return (
    <PageSection backgroundColor={stegaClean(props?.contentColor) ?? 'white'}>
      <ContentContainer layout={props?.isArticle ? 'article' : 'medium-padding'} className="is--text-and-image contentContainer">
        <div className="hidden-anchor" id={stegaClean(props?.headingText)?.toLowerCase().replace(/[^A-Z0-9]/ig, "-")} />
        <div className={`${styles.container} is--module ${props?.contentColor ? stegaClean(props?.contentColor) : ''} layout--${stegaClean(props.layoutType)}${props?.isArticle ? ' content--narrow' : ''}`}>
          <div className={styles.textContainer}>
            <HeadingAndText 
              headingText={props.headingText ?? ""} 
              bodyText={props.bodyText} 
              textAlign={stegaClean(props.layoutType) === 'textInCenter' ? 'center' : 'left'} 
              subHeadingText={props.subHeadingText} 
              promDateRange={promDateRange}
              badgePromo={props?.badge} />

            {props.button && props.button.buttonVisible && (
              <ButtonContainer layout={stegaClean(props.layoutType) !== 'textInCenter' ? "align-left" : "full-width-mobile"}>
                <Button
                  href={props?.button?.buttonLink ?? ""}
                  label={props?.button?.buttonLabel ?? ""}
                  style={stegaClean(props?.button.buttonVariant) ?? 'primary'}
                  target={stegaClean(props.button.linkType) === 'external link' ? '_blank' : ''}
                  size="large"
                  rollerProductID={props?.button?.rollerID}
                  linkType={props?.button?.linkType}
                />
              </ButtonContainer>
            )}
          </div>
          {stegaClean(props.layoutType) === 'textInCenter' && 
            <div className={styles.columnBreak} />
          }
          <div className={styles.mediaContainer}>
            {props.media && props.media.imageSource && !props.video && !props.media.videoYT && 
              <div className={`${styles.imageContainer} image-ratio--default`}>
                <Image
                  src={`${props.image.url}?q=80&w=600&format=auto`}
                  alt={props.media.imageSource?.imageAlt ?? ""}
                  fill={true}
                  style={{ objectFit: "cover" }}
                  placeholder="blur"
                  blurDataURL={`${props.image?.lqip ?? props.image.url}`}
                  quality={80}
                />
              </div>
            }
            {(props.video || props.media?.videoYT) && 
              <div className={styles.videoContainer}>
                <Video video={props.video || props.media} videoPlaceholder={props.videoPlaceholder} />
              </div>
            }
          </div>
        </div>
        {props.graphic &&
          <Graphic type={stegaClean(props.graphic?.graphicItem)} align={stegaClean(props.graphic?.graphicPos)} />
        }
      </ContentContainer>
    </PageSection>
  );
}