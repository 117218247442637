import * as styles from "./syles.css";
import PageSection from "@/components/page-section";
import ContentContainer from "@/components/content-container";
import Image from "next/image";
import { stegaClean } from "@sanity/client/stega";
import Video from "@/components/video";

interface Props  {
  video?: any;
  videoPlaceholder?: any;
  image?: any;
  imageSource?: any;
  mediaType?: any;
  media?: any;
  videoYT?: any;
}

export default function ArticleMedia(props: Props) {
  return (
    <PageSection backgroundColor={'white'}>
      <ContentContainer layout="article" className="contentContainer">
        <div className={`${styles.mediaContainer} content--narrow`}>
          {props?.imageSource && stegaClean(props?.mediaType) === 'upload image' && 
            <div className={styles.pictureContainer}>
              <Image
                src={`${props.imageSource?.url}?&q=90&auto=format`}
                alt={props.imageSource?.imageAlt ?? ""}
                placeholder="blur"
                width={props?.imageSource?.dimensions?.width}
                height={props?.imageSource?.dimensions?.height}
                blurDataURL={`${props.imageSource?.lqip ?? `${props.imageSource?.url}?w=10&auto=format`}`}
              />
            </div>
          }
          {(props.video || props?.videoYT) && 
            <div className={styles.videoContainer}>
              <Video video={props.video || props} videoPlaceholder={props.videoPlaceholder} />
            </div>
          }
        </div>
      </ContentContainer>
    </PageSection>
  );
}