import Button from "@/components/button";
import ButtonContainer from "@/components/button-container";
import TextandImage from "@/modules/text-and-image";
import Link from "next/link";
import { stegaClean } from "@sanity/client/stega";
import ArticleMedia from "@/modules/article-media";

const ImageComponent = ({value}: any) => {
  return (
    <div className="is--portable-text">
      <TextandImage {...value} isArticle={true} />
    </div>
  )
}
const MediaComponent = ({value}: any) => {
  return (
    <div className="is--portable-text">
      <ArticleMedia {...value} />
    </div>
  )
}
const ButtonComponent = ({value}: any) => {
  return (
    <div className="is--portable-text button-focus--offset">
      <ButtonContainer layout="align-left">
        <Button
          href={value?.buttonLink || ""}
          label={value?.buttonLabel || ""}
          style={stegaClean(value.buttonVariant) || "tertiary"}
          target={stegaClean(value.linkType) === 'external link' ? '_blank' : ''}
          rollerProductID={value?.rollerID}
          linkType={value?.linkType}
        />
      </ButtonContainer>
    </div>
  )
}

export const ptComponents = {
  block: {
    normal: ({ children }: any) => <p>{children}</p>,
    p: ({ children }: any) => <p>{children}</p>,
    h2: ({children}: any) => <h2>{children}</h2>,
    h3: ({children}: any) => <h3>{children}</h3>,
    blockquote: ({ children }: any) => <blockquote>{children}</blockquote>,
    spanColor: ({ children }: any) => <span className="text--green">{children}</span>,
  },
  listItem: {
    bullet: ({ children }: any) => <li>{children}</li>,
    tick: ({ children }: any) => <li className="is--tick-list">{children}</li>
  },
  types: {
    mediaAndText: ImageComponent,
    buttonTertiary: ButtonComponent,
    media: MediaComponent
  },
  marks: {
    link: ({value, children}: any) => {
      return <a href={value.url ?? '/'} target="_blank">{children}</a>
    },
    internalLink: ({value, children}: any) => {
      return <Link href={value?.reference?.buttonLink ?? '/'} prefetch={false}>{children}</Link>
    }
  }
};