"use client"

import * as styles from "./styles.css";
import Image from "next/image";
import { useRef, useState } from "react";
import Button from "../button";

interface Props {
  video: any;
  videoPlaceholder: any
}

export default function Video(props: Props) {
  const [videoActivated, setVideoActivated] = useState(false);
  const videoObject = useRef<HTMLVideoElement>(null);
  const iframeObject = useRef<any>(null);
  

  const videoHandler = () => {
    if (videoObject.current) {
      videoObject.current.play();
    }

    setVideoActivated(true);
  };
  const ytVideoHandler = () => {
    if (iframeObject.current) {
      iframeObject.current.src += '&autoplay=1';
    }
    setVideoActivated(true);
  }

  return (
    <div>
      <div className={`${styles.videoContainer} image-ratio--default`}>
        <div className={styles.videoItem}>
          {props.video.url &&
            <>
              {props.videoPlaceholder && !props.video.videoYT && (
                <div className={`${styles.imageContainer}${videoActivated ? ' is--hidden' : ''}`} onClick={() => videoHandler()}>
                  <div className={styles.playButtonGraphic}>
                    <Button style="primaryCircular" circular="play" />
                  </div>
                  <Image
                    src={`${props?.videoPlaceholder?.source?.url}?h=700&q=80&auto=format`}
                    alt={props?.videoPlaceholder?.imageAlt || ""}
                    fill={true}
                    style={{ objectFit: "cover" }}
                    placeholder="blur"
                    blurDataURL={props?.videoPlaceholder?.source?.url}
                    quality={80}
                  />
                </div>
              )}
              <video className={`${videoActivated ? 'is--active' : ''}`} ref={videoObject} width="320" height="240" controls src={props.video.url} />
            </>
          }
          {props.video.videoYT &&
            <div className={styles.ytContainer}>
              {props.videoPlaceholder &&
                <div className={`${styles.imageContainer}${videoActivated ? ' is--hidden' : ''}`} onClick={() => ytVideoHandler()}>
                  <div className={styles.playButtonGraphic}>
                    <Button style="primaryCircular" circular="play" />
                  </div>
                  <Image
                    src={`${props?.videoPlaceholder?.source?.url}?h=700&q=80&auto=format`}
                    alt={props?.videoPlaceholder?.imageAlt || ""}
                    fill={true}
                    style={{ objectFit: "cover" }}
                    placeholder="blur"
                    quality={80}
                    blurDataURL={props?.videoPlaceholder?.source?.url}
                  />
                </div>
              }
              <div className={`${styles.ytContainer} ${videoActivated ? 'is--active' : ''}`}>
                <iframe ref={iframeObject} width="560" height="315" src={props.video.videoYT} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen style={{border: 0}} />
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}
