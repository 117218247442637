"use client";

import * as styles from "./styles.css";
import { useEffect, useRef, useState } from "react";

type Props = {
  isCardBite?: boolean
  isPolicyHeader?: boolean
}

export default function BiteMark(props: Props) {
  const currentDivRef = useRef<HTMLDivElement>(null);
  const [prevBackgroundColor, setPrevBackgroundColor] = useState("");
  const [parentBackgroundColor, setParentBackgroundColor] = useState("");
  useEffect(() => {
    if (currentDivRef.current && !props.isPolicyHeader) {
      const parentDiv = currentDivRef.current.parentElement;
      if (parentDiv) {
        const parentBackgroundColor =
          window.getComputedStyle(parentDiv).backgroundColor;
        setParentBackgroundColor(parentBackgroundColor);
        const prevSiblingOfParent =
          parentDiv.previousElementSibling as HTMLElement | null;
        if (prevSiblingOfParent) {
          const prevBackgroundColor =
            window.getComputedStyle(prevSiblingOfParent).backgroundColor;
          setPrevBackgroundColor(prevBackgroundColor);
        }
      }
    } else if (props.isPolicyHeader) {
      setPrevBackgroundColor('#E6F5EC');
      setParentBackgroundColor('#009943')
    }
  }, [props, setPrevBackgroundColor, setParentBackgroundColor]);
  return (
    <div
      className={`${styles.container}${props.isCardBite ? ' is--card-bite' : ''}${props.isPolicyHeader ? ' is--policy' : ''}`}
      ref={currentDivRef}
      style={{ backgroundColor: prevBackgroundColor }}
    >
      <div className={styles.shadow}>
        <div
          className={styles.background}
          style={{ backgroundColor: parentBackgroundColor }}
        >
          <div className={styles.content}>
            <svg
              className={styles.bite}
              viewBox="0 0 205 67"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0.903625 0C2.34341 7.63507 6.73663 14.5569 13.6865 18.8695C18.2225 21.6842 23.2724 23.0305 28.3146 23.0435C26.471 34.4601 31.1746 46.1541 41.2256 52.391C51.2762 58.6277 63.8502 57.6555 73.2725 50.9424C75.52 55.4513 78.9662 59.3761 83.5009 62.19C97.0473 70.5959 115.177 65.9062 123.997 51.7152C137.543 60.1201 155.673 55.4295 164.492 41.238C167.566 36.2897 169.103 30.8398 169.227 25.4831C180.794 27.5978 193.25 22.3066 200.095 11.2906C202.317 7.71426 203.736 3.87585 204.399 0H0.903625Z" />
            </svg>
            <svg width="0" height="0">
              <filter id="remove-black" colorInterpolationFilters="sRGB">
                <feColorMatrix
                  type="matrix"
                  values="1 0 0 0 0
                0 1 0 0 0
                0 0 1 0 0
                -255 -255 -255 0 1"
                  result="black-pixels"
                />
                <feMorphology
                  in="black-pixels"
                  operator="dilate"
                  radius="0.5"
                  result="smoothed"
                />
                <feComposite in="SourceGraphic" in2="smoothed" operator="out" />
              </filter>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
